import React, {FC, ReactNode, useState} from "react";
import ReactDOM from "react-dom/client";
import "./styles/index.scss";
import App from "./App";
import {BrowserRouter} from "react-router-dom";
import store from "./store";
import {Provider} from "react-redux";
import "./i18n/i18n";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "reactjs-popup/dist/index.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "react-toastify/dist/ReactToastify.css";
import "@rainbow-me/rainbowkit/styles.css";
import {QueryClient, QueryClientProvider} from "@tanstack/react-query";
import {configureChains, createConfig, useAccount, WagmiConfig} from "wagmi";
import {mainnet, xdc} from "wagmi/chains";
import {connectorsForWallets, createAuthenticationAdapter, darkTheme, lightTheme, RainbowKitAuthenticationProvider, RainbowKitProvider,} from "@rainbow-me/rainbowkit";
import {useDarkMode, useLocalStorage} from "usehooks-ts";
import CustomAvatar from "./components/avatar-custom";
import {ledgerWallet, metaMaskWallet, okxWallet, phantomWallet, rabbyWallet, trustWallet, walletConnectWallet,} from "@rainbow-me/rainbowkit/wallets";
import {rainbowWeb3AuthConnector} from "./connectors/rainbowWeb3AuthConnector";
import {publicProvider} from "wagmi/providers/public";
import {SiweMessage} from "siwe";
import {profileAccountService} from "./store/actions";
import {common} from "./store/slicer/common";

// Set theme color
const setThemeColor = () => {
    const bsTheme: any = {
        "--color-theme": process.env.REACT_APP_BS_THEME || "#081d35",
        "--color-primary": process.env.REACT_APP_BS_PRIMARY || "#34a1ff",
    };
    const root = window.document.documentElement;
    if (root) {
        for (let label in bsTheme) {
            const value = bsTheme[label];
            root.style.setProperty(label, value);
        }
    }
};

// Configure chains and clients
const {chains, publicClient, webSocketPublicClient} = configureChains(
    [xdc, mainnet],
    [publicProvider()]
);

const connector = connectorsForWallets([
    {
        groupName: "Recommended",
        wallets: [
            metaMaskWallet({chains}),
            trustWallet({chains}),
            rabbyWallet({chains}),
            walletConnectWallet({chains}),
            phantomWallet({chains}),
            okxWallet({chains}),
            ledgerWallet({chains}),
        ],
    },
    {
        groupName: "Others",
        //@ts-ignore
        wallets: [rainbowWeb3AuthConnector({chains})],
    },
]);

const config = createConfig({
    autoConnect: false,
    connectors: connector,
    publicClient,
    webSocketPublicClient,
});

// Dark theme wrapper component
interface DarkThemeWrapperProps {
    children: ReactNode;
}

const DarkThemeWrapper: FC<DarkThemeWrapperProps> = ({children}) => {
    const {isDarkMode} = useDarkMode();
    const [mode] = useLocalStorage("isDarkMode", isDarkMode ? "dark" : "light");
    const theme = mode === "light" ? lightTheme() : darkTheme();
    return (
        <RainbowKitProvider avatar={CustomAvatar} theme={theme} chains={chains}>
            {children}
        </RainbowKitProvider>
    );
};

setThemeColor();
const root = ReactDOM.createRoot(
    document.getElementById("bs-root") as HTMLElement
);

const Index = () => {
    const {isConnected} = useAccount();
    const [authStatus, setAuthStatus] = useState<"unauthenticated" | "authenticated">("unauthenticated");

    const generateSecureNonce = () => {
        const array = new Uint8Array(16);
        window.crypto.getRandomValues(array);
        return Array.from(array, (byte) => byte.toString(16).padStart(2, "0")).join(
            ""
        );
    };

    const authenticationAdapter = createAuthenticationAdapter({
        getNonce: async () => {
            return generateSecureNonce();
        },
        createMessage: ({nonce, address, chainId}) => {
            return new SiweMessage({
                domain: window.location.host,
                address,
                statement: "Sign in with Ethereum to the app.",
                uri: window.location.origin,
                version: "1",
                chainId,
                nonce,
            });
        },
        getMessageBody: ({message}) => {
            return message.prepareMessage();
        },
        verify: async ({signature, message}) => {
            const response = await profileAccountService.getVerified({message: message.prepareMessage(), signature});
            if (response?.message === "Verification successful.") {
                setAuthStatus("authenticated");
                store.dispatch(common.actions.actionAccount({signature, ...response?.userData}))
                return true;
            }
            return false;
        },
        signOut: async () => {
            setAuthStatus("unauthenticated");
        },
    });

    return (
        <Provider store={store}>
            <WagmiConfig config={config}>
                <QueryClientProvider client={new QueryClient()}>
                    <RainbowKitAuthenticationProvider
                        adapter={authenticationAdapter}
                        status={authStatus}
                    >
                        <DarkThemeWrapper>
                            <BrowserRouter>
                                <App/>
                            </BrowserRouter>
                        </DarkThemeWrapper>
                    </RainbowKitAuthenticationProvider>
                </QueryClientProvider>
            </WagmiConfig>
        </Provider>
    );
};

root.render(<Index/>);
