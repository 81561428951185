//@ts-ignore
import web3 from "web3";
import BigNumber from "bignumber.js";
import {toast} from "react-toastify";
import moment from "moment";
import ImgError from "../../images/img-error.svg";
import NFTPlaceHolder from "../../images/nft-placeholder.svg";
import ReactGA from "react-ga4";

export const get0xAddress = (
  address: string = "",
  prefix: string = localStorage.getItem("prefix") ||
    process.env.REACT_APP_PREFIX ||
    "xdc"
): string => {
  const localPrefix = process.env.REACT_APP_PREFIX || "xdc";
  if (typeof address !== "string" || !address) return address;
  const start: string = address?.slice(0, localPrefix?.length);
  if (prefix === "0x")
    return start.toLowerCase() === localPrefix
      ? "0x" + address.substring(localPrefix?.length)
      : address;
  else
    return start.toLowerCase() === prefix
      ? "0x" + address.substring(prefix?.length)
      : address;
};

// Custom event
export const AnalyticEvent = (category: string, action: any, label: string, value: number) => {
 ReactGA.event({
  category: "your category",
  action: "your action",
  label: "your label", // optional
  value: 99, // optional, must be a number
});
}

export const getXDCAddress = (
  address: string = "",
  prefix: string = localStorage.getItem("prefix") ||
    process.env.REACT_APP_PREFIX ||
    "xdc"
): string => {
  const start: string = address?.slice(0, 2) || "";
  return start.toLowerCase() === "0x" ? prefix + address.substring(2) : address;
};

export const calculateTokenValue = (
  value: number = 0, 
  decimal: number = 18,
): JSX.Element | string => {
  // Input validation
  if (isNaN(value) || isNaN(decimal)) {
    console.error("Invalid input values:", value, decimal);
    return "-";
  }

  try {
    const valueBigNumber = new BigNumber(value);
    const dividedValue = valueBigNumber.dividedBy(new BigNumber(10).pow(decimal));
    const formattedResult = dividedValue.toFixed();
    const formatnum = parseFloat(formattedResult);
    const formattedString = formatnum.toString();

    const [integerPart, decimalPart] = formattedResult.split('.');
    const totalDigits = (decimalPart ? decimalPart.length : 0);
    // const intLength = integerPart.length;

    // If the decimal part has more than 5 digits
    if (decimalPart && decimalPart.length > 5) {
      const formattedDecimalPart = decimalPart.slice(0, 4);
      const finaldigit = totalDigits;
      return (
        <>
          0.0<sub className="sub-text">{finaldigit}</sub>{formattedDecimalPart}
        </>
      );
    } 

    if (formattedString.length > 12) {
      const finalVal = formattedString.slice(0, 12);
      return (
        <>
          {finalVal}<sub>{totalDigits}</sub>
        </>
      );
    }

    return formattedString;
  } catch (error) {
    console.error("Error in calculateTokenValue:", error);
    return "-";
  }
};

export const getFiat = (
  price: number | string = 0,
  decimals: number = 4,
  fiat: string = localStorage.getItem("fiat") || "USD",
  format?: boolean,
  fixed?: number
): string => {
  const condition = fiat === "USD";
  const list = localStorage.getItem("fiatList");
  const fiatList = list ? JSON.parse(list) : {};
  const parsedPrice =
    typeof price === "string" ? parseFloat(price.replace(/,/g, "")) : price;
  const finalPrice = parsedPrice * (fiatList[fiat] || 1);

  // Determine the number of decimal places based on the price
  const adjustedDecimals = parsedPrice > 1 ? 2 : decimals;

  return price ? `${condition ? "$" : ""} ${format ? formatNumber(finalPrice.toFixed(adjustedDecimals), fixed) : formatDecimalWithSubscript(finalPrice.toFixed(adjustedDecimals))} ${condition ? "" : fiat}` : "-";
};


export const getFixed = (number = 0, precision = 3) => {
  let factor = Math.pow(10, precision);
  return Math.round(number * factor) / factor;
};

export const getPlural = (prefix: any, value: any) => {
  return `${getFixed(value, 3)} ${prefix}${value > 1 ? "s" : ""}`;
};

export function formatTimestampDate(timestamp: number): string {
  return moment(timestamp).format('YYYY-MM-DD');
}

export const getTimeFromNow = (datetime: any) => {
  const currentTime = moment();
  const targetTime = moment(datetime);
  const duration = moment.duration(currentTime.diff(targetTime));

  const seconds = duration.seconds();
  const minutes = duration.minutes();
  const hours = duration.hours();
  const days = duration.days();

  if (days > 0) {
    return `${days} ${days === 1 ? "day" : "days"} ago`;
  } else if (hours > 0) {
    return `${hours} ${hours === 1 ? "hour" : "hours"} ago`;
  } else if (minutes > 0) {
    return `${minutes} ${minutes === 1 ? "minute" : "minutes"} ago`;
  } else {
    return `${seconds} ${seconds === 1 ? "second" : "seconds"} ago`;
  }
};
export const formatValue = (value: number): string => {
  const roundedValue = Math.round(value);
  return roundedValue >= 0 ? `+${roundedValue}` : `${roundedValue}`;
};

export function timeAgo(timestamp: string): string {
    const time = moment(timestamp);
    const now = moment();
    
    const totalSeconds = now.diff(time, 'seconds');
    const years = Math.floor(totalSeconds / (365 * 24 * 60 * 60));
    const months = Math.floor((totalSeconds % (365 * 24 * 60 * 60)) / (30 * 24 * 60 * 60));
    const days = Math.floor((totalSeconds % (30 * 24 * 60 * 60)) / (24 * 60 * 60));
    const hours = Math.floor((totalSeconds % (24 * 60 * 60)) / (60 * 60));
    const minutes = Math.floor((totalSeconds % (60 * 60)) / 60);
    const seconds = totalSeconds % 60;

    if (years > 0) {
        return `${years} year${years > 1 ? 's' : ''} ago`;
    } else if (months > 0) {
        return `${months} month${months > 1 ? 's' : ''} ago`;
    } else if (days > 0) {
        return `${days} day${days > 1 ? 's' : ''} ago`;
    } else if (hours > 0) {
        return `${hours} hour${hours > 1 ? 's' : ''} ago`;
    } else if (minutes > 0) {
        return `${minutes} minute${minutes > 1 ? 's' : ''} ago`;
    } else if (seconds > 0) {
        return `${seconds} second${seconds > 1 ? 's' : ''} ago`;
    } else {
        return 'just now';
    }
}
export const convertSmallerUnit = (value: number | string): number => {
  const bigValue = typeof value === "string" ? parseFloat(value) : value;
  return bigValue / 1e18;
};
export const calculateTransactionFeeSavings = (
  maxFeePerGas: string,
  baseFee: string,
  priorityFee: string,
  gasUsed: string
): string => {
  const maxFee = BigInt(maxFeePerGas);
  const fee = BigInt(baseFee);
  const priority = BigInt(priorityFee);
  const gas = BigInt(gasUsed);
  const feeSavings = (maxFee - priority - fee) * gas;
  const savingsInEther = parseFloat((Number(feeSavings) / 1e18).toFixed(18)).toString();
  return savingsInEther;
};

export const getTimeFromNowShort = (datetime: any) => {
  const currentTime = moment();
  const targetTime = moment(datetime);

  const totalDays = currentTime.diff(targetTime, 'days');
  const totalHours = currentTime.diff(targetTime, 'hours');
  const totalMinutes = currentTime.diff(targetTime, 'minutes');
  const totalSeconds = currentTime.diff(targetTime, 'seconds');
  
  if (totalDays > 0) {
    return `${totalDays} ${totalDays === 1 ? "day" : "days"} ago`;
  } else if (totalHours > 0) {
    return `${totalHours} ${totalHours === 1 ? "hr" : "hrs"} ago`;
  } else if (totalMinutes > 0) {
    return `${totalMinutes} ${totalMinutes === 1 ? "min" : "mins"} ago`;
  } else {
    return `${totalSeconds} ${totalSeconds === 1 ? "sec" : "secs"} ago`;
  }
};
export const getTimeLang = (datetime: any, t: Function) => {
  const targetTime = datetime ? moment(datetime) : moment();
  const currentTime = moment();
  const duration = moment.duration(currentTime.diff(targetTime));

  const seconds = duration.asSeconds();

  if (seconds < 60) {
    const roundedSeconds = Math.round(seconds);
    return `${roundedSeconds} ${t(roundedSeconds === 1 ? "sec" : "secs")} ${t("ago")}`;
  } else if (seconds < 3600) {
    const minutes = Math.round(seconds / 60);
    return `${minutes} ${t(minutes === 1 ? "min" : "mins")} ${t("ago")}`;
  } else if (seconds < 86400) {
    const hours = Math.round(seconds / 3600);
    return `${hours} ${t(hours === 1 ? "hr" : "hrs")} ${t("ago")}`;
  } else {
    const days = Math.round(seconds / 86400);
    return `${days} ${t(days === 1 ? "day" : "days")} ${t("ago")}`;
  }
};

export const getDateFromTimestamp = (timestamp: number, format: string = 'dddd, MMMM DD, YYYY'): string | null => {
    if (!timestamp) {
        return null;
    }
    return moment(timestamp).format(format); 
};

export const getDuration = (start: any, end: any) => {
  let d =
    !!start && !!end
      ? (new Date(start).getTime() - new Date(end).getTime()) / 1000
      : 0;
  if (d < 0) d = d * -1;
  d = Number(d);
  let h = Math.floor(d / 3600);
  let m = Math.floor((d % 3600) / 60);
  let s = Math.floor((d % 3600) % 60);
  if (h > 0)
    return `${getPlural("hour", h)} ${getPlural("minute", m)} ${getPlural(
      "second",
      s
    )}`;
  else if (m > 0)
    return `${m} ${getPlural("minute", m)} ${getPlural("second", s)}`;
  else return getPlural("second", s);
};
export const getDurationShort = (start: any, end: any) => {
  let d =
    !!start && !!end
      ? (new Date(start).getTime() - new Date(end).getTime()) / 1000
      : 0;
  if (d < 0) d = d * -1;
  d = Number(d);
  let h = Math.floor(d / 3600);
  let m = Math.floor((d % 3600) / 60);
  let s = Math.floor((d % 3600) % 60);
  if (h > 0)
    return `${getPlural("hr", h)} ${getPlural("min", m)} ${getPlural(
      "sec",
      s
    )}`;
  else if (m > 0) return `${m} ${getPlural("min", m)} ${getPlural("sec", s)}`;
  else return getPlural("sec", s);
};
export const formatNumber = (number: any = 0, fixed: any = 6) => {
  if (typeof fixed === 'number' && number) {
    if (typeof number === "string") number = parseFloat(number);
    number = number.toFixed(fixed);
  }
  number = new BigNumber(number.toString());
  const seps = number.toString().split(".");
  seps[0] = seps[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return seps.join(".");
};

export const formatNumberWithSuffix = (number: any = 0, fixed: any = 4) => {
  if (fixed && number) {
    if (typeof number === "string") number = parseFloat(number);
    number = number.toFixed(fixed);
  }
  number = new BigNumber(number.toString());

  const abbreviations = ["", "k", "M", "B", "T"];
  let abbreviationIndex = 0;

  while (number.gte(1000) && abbreviationIndex < abbreviations.length - 1) {
    number = number.dividedBy(1000);
    abbreviationIndex++;
  }

  const formattedNumber = number
    .toNumber()
    .toLocaleString("en-US", { maximumFractionDigits: 2 });

  return `${formattedNumber}${abbreviations[abbreviationIndex]}`;
};

export const LICENSE: { [key: number]: string } = {
    1: "none",
    2: "unlicense",
    3: "mit",
    4: "gnu_gpl_v2",
    5: "gnu_gpl_v3",
    6: "gnu_lgpl_v2_1",
    7: "gnu_lgpl_v3",
    8: "bsd_2_clause",
    9: "bsd_3_clause",
    10: "mpl_2_0",
    11: "osl_3_0",
    12: "apache_2_0",
    13: "gnu_agpl_v3",
    14: "bsl_1_1"
};

export const getLicenseText = (licenseType: number): string => {
    return LICENSE[licenseType] || "-";
};

export const getConfirmationDuration = (confirmationDuration: [number, number]): string => {
    const [startTime, endTime] = confirmationDuration;
    const durationInSeconds = (endTime - startTime) / 1000;
    const formattedDuration = durationInSeconds > 1 ? Math.round(durationInSeconds) : durationInSeconds;
    return `${formattedDuration} secs`;
};

export function formatTimestamp(timestamp: string | null): string {

  const date = timestamp ? new Date(timestamp) : new Date();

  const formattedDate = `${date.getFullYear()}-${padZero(date.getMonth() + 1)}-${padZero(date.getDate())}`;
  const formattedTime = `${padZero(date.getHours())}:${padZero(date.getMinutes())}:${padZero(date.getSeconds())}`;

  return `${formattedDate} ${formattedTime}`;
}

function padZero(num: number): string {
  return num < 10 ? `0${num}` : `${num}`;
}

export function formatNumberWithCommas(number: string | number): string {
  if (typeof number !== 'string') {
    number = number.toString();
  }
  let [integerPart, decimalPart] = number.split('.');
  integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return decimalPart ? `${integerPart}.${decimalPart}` : integerPart;
}

export const toNumber = (
  wei: any = "0",
  decimal: number = 18,
  fixed: number = 0
) => {
  if (wei === null || wei === undefined) return "0";
  if (isNaN(wei)) return "0";
  if (typeof wei !== "string") wei = wei.toString() || "0";
  BigNumber.config({ EXPONENTIAL_AT: [-100, 100] });
  let weiNumber: any = new BigNumber(wei);
  const divided = 10 ** decimal;
  weiNumber = weiNumber.dividedBy(divided).toString();
  return weiNumber;
};
export const toFormatNumber = (
  wei: any = "0",
  decimal: number = 18,
  fixed: number = 0
) => {
  if (wei === null || wei === undefined) return "0";
  if (isNaN(wei)) return "0";
  if (typeof wei !== "string") wei = wei.toString() || "0";
  BigNumber.config({ EXPONENTIAL_AT: [-100, 100] });
  let weiNumber: any = new BigNumber(wei);
  const divided = 10 ** decimal;
  weiNumber = weiNumber.dividedBy(divided);

  // Check if weiNumber is less than 1
  if (weiNumber.isLessThan(1)) {
    return wei;
  }
  return weiNumber.toFixed(fixed);
};

export const toXDCFormatNumber = (
  wei: any = "0",
  decimal: number = 18,
  fixed: number = 0
) => formatNumber(toFormatNumber(wei, decimal), fixed);
export const toXDCNumberWoutFormat = (
  wei: any = "0",
  decimal: number = 18
) => parseFloat(toFormatNumber(wei, decimal));

export const formatSuffix = (number: any = 0): string => {
  number = new BigNumber(number.toString());

  const abbreviations = ["", "k", "M", "B", "T"];
  let abbreviationIndex = 0;

  while (number.gte(1000) && abbreviationIndex < abbreviations.length - 1) {
    number = number.dividedBy(1000);
    abbreviationIndex++;
  }
  let formattedNumber = "";

  if (abbreviationIndex === 0) {
    formattedNumber = Math.round(Number(number)).toString();
  } else {
    formattedNumber = number.integerValue(BigNumber.ROUND_FLOOR).toString();
  }

  const suffix = abbreviations[abbreviationIndex];
  return `${formattedNumber}${suffix}`;
};
export const toXDCNumber = (
  wei: any = "0",
  decimal: number = 18,
  fixed: number = 0
) => formatNumber(toNumber(wei, decimal), fixed);

export const toXDCNumberFix = (
  wei: any = "0",
  decimal: number = 18,
  fixed: number = 4
) => formatNumber(toNumber(wei, decimal), fixed);

export const toXDCNumberFixValue = (
  wei: any = "0",
  decimal: number = 18,
  fixed: number = 8
) => formatNumber(toNumber(wei, decimal), fixed);

export const toXDCNumberWithoutFormat = (
  wei: any = "0",
  decimal: number = 18
) => parseFloat(toNumber(wei, decimal));

export const baseUnit = (baseUnit = null) => baseUnit || process.env.REACT_APP_BASE_UNIT;
export const formatUnit = (number = 0, unit: any = null) => formatDecimalWithSubscript(number) + " " + baseUnit(unit);
export const toGwei = (wei: any) =>
  wei ? formatNumber(web3.utils.fromWei(wei, "gwei")) : "";
export const formatPer = (quantity: number = 0, total: number = 1, decimalPlaces: number = 4) => {
  const percentage = (quantity / total) * 100;
  return percentage.toFixed(decimalPlaces);
};

export const formatPercent = (
  quantity: number | string = 0, 
  total: number | string = 1, 
  decimalPlaces: number = 4
): string => {
  if (typeof total === 'string') {
    total = parseFloat(total);
  }
  if (total === 0) {
    return '0.0000';  // Return a default value when total is zero
  }
  const quantityBN = new BigNumber(quantity);
  const totalBN = new BigNumber(total);
  const percentage = quantityBN.dividedBy(totalBN).multipliedBy(100);

  return percentage.toFixed(decimalPlaces);
};

export function formatSign(numberStr: string): string {
    if (numberStr === '' || numberStr === '+' || numberStr === '-') {
        throw new Error("Invalid input: Please provide a valid number.");
    }
    const number = parseFloat(numberStr);
    if (isNaN(number)) {
        throw new Error("Invalid input: Not a number.");
    }
    const hasPrefix = numberStr.startsWith('+') || numberStr.startsWith('-');
    if (hasPrefix) {
        return numberStr;
    }
    return number >= 0 ? `+${number}` : `${number}`;
}

export const getCurrencyValue = ({
  value,
  accuracy,
  accuracyUsd,
  decimals,
  exchangeRate,
}: any) => {
  const valueCurr = new BigNumber(value).div(
    new BigNumber(10).exponentiatedBy(Number(decimals || "18"))
  );
  const valueResult = accuracy
    ? valueCurr.dp(accuracy).toString()
    : valueCurr.toString();
  let usdResult: string | undefined;
  let usdBn = new BigNumber(0);

  if (exchangeRate) {
    const exchangeRateBn = new BigNumber(exchangeRate);
    usdBn = valueCurr.times(exchangeRateBn);
    if (accuracyUsd && !usdBn.isEqualTo(0)) {
      const usdBnDp = usdBn.dp(accuracyUsd);
      usdResult = usdBnDp.isEqualTo(0)
        ? usdBn.toFixed(accuracyUsd)
        : usdBnDp.toFixed();
    } else {
      usdResult = usdBn.toFixed();
    }
  }

  return { valueStr: valueResult, usd: usdResult, usdBn };
};

export function uniqid(prefix = "", random = false) {
  const sec = Date.now() * 1000 + Math.random() * 1000;
  const id = sec.toString(16).replace(/\./g, "").padEnd(14, "0");
  return `${prefix}${id}${
    random ? `.${Math.trunc(Math.random() * 100000000)}` : ""
  }`;
}

// @ts-ignore
export const serializeQuery = (params: any) => {
  if (!params) return "";
  const query: any = Object.keys(params).map((key) => {
    const value = params[key];
    return value === "object"
      ? serializeQuery(value)
      : `${key}=${encodeURIComponent(value)}`;
  });

  return `?${[].concat.apply([], query).join("&")}`;
};

export const centerTruncate = (str: string): string => {
  if (typeof str !== "string" || str.length < 12) {
    return str;
  }
  const start = str.substr(0, 10);
  const end = str.substr(str.length - 9);
  return start + "..." + end;
};
export const truncate = (str: string = "", length: number = 13): string => {
  return str?.length > length ? (str.substr(0, length) + "...") : str;
};

export const truncateText = (
  str: string | null | undefined,
  numbers: number = 25
): string => {
  if (str && str.length > numbers) {
    return str.substr(0, numbers) + "...";
  } else {
    return str || "";
  }
};

export const showSuccess = (message: any, option?: any) => {
  toast.success(message || "Success !", { ...option });
};
export const showError = (message: any, option?: any) => {
  toast.error(message || "Error !", { ...option });
};

function addFilesToFormData(
  body: FormData,
  files: Array<File> | undefined,
  fieldName: "files" | "interfaces"
) {
  if (!files) {
    return;
  }

  for (let index = 0; index < files.length; index++) {
    const file = files[index];
    body.set(`${fieldName}[${index}]`, file, file.name);
  }
}

function reduceLibrariesArray(libraries: Array<any> | undefined) {
  if (!libraries || libraries.length === 0) {
    return;
  }

  return libraries.reduce<Record<string, string>>((result, item) => {
    result[item.name] = item.address;
    return result;
  }, {});
}

export function prepareRequestBody(data: any, files?: any) {
  const defaultLicenseType: string = "none";

  switch (data?.method?.value) {
    case "flattened-code": {
      const _data = data as any;
      return {
        compiler_version: _data.compiler_version?.value,
        source_code: _data.source_code,
        is_optimization_enabled: _data.is_optimization_enabled,
        is_yul_contract: _data.is_yul_contract,
        optimization_runs: _data.optimization_runs,
        contract_name: _data.contract_name || undefined,
        libraries: reduceLibrariesArray(_data.libraries),
        evm_version: _data.evm_version?.value,
        autodetect_constructor_args: _data.autodetect_constructor_args,
        constructor_args: _data.constructor_args,
        license_type: _data.license_type?.value ?? defaultLicenseType,
      };
    }

    case "standard-input": {
      const _data = data as any;

      const body = new FormData();
      body.set("compiler_version", _data.compiler_version?.value || "");
      body.set("license_type", _data.license_type?.value ?? defaultLicenseType);
      body.set("contract_name", _data.contract_name);
      body.set(
        "autodetect_constructor_args",
        String(Boolean(_data.autodetect_constructor_args))
      );
      body.set("constructor_args", _data.constructor_args);
      addFilesToFormData(body, files, "files");

      return body;
    }

    case "sourcify": {
      const _data = data as any;
      const body = new FormData();
      addFilesToFormData(body, files, "files");
      body.set(
        "chosen_contract_index",
        _data.contract_index?.value ?? defaultLicenseType
      );
      _data.license_type && body.set("license_type", _data.license_type.value);

      return body;
    }

    case "multi-part": {
      const _data = data as any;

      const body = new FormData();
      _data.compiler &&
        body.set("compiler_version", _data.compiler_version.value);
      _data.evm_version && body.set("evm_version", _data.evm_version.value);
      body.set("license_type", _data.license_type?.value ?? defaultLicenseType);
      body.set(
        "is_optimization_enabled",
        String(Boolean(_data.is_optimization_enabled))
      );
      _data.is_optimization_enabled &&
        body.set("optimization_runs", _data.optimization_runs);

      const libraries = reduceLibrariesArray(_data.libraries);
      libraries && body.set("libraries", JSON.stringify(libraries));
      addFilesToFormData(body, files, "files");

      return body;
    }

    case "vyper-code": {
      const _data = data as any;

      return {
        compiler_version: _data.compiler_version?.value,
        evm_version: _data.evm_version?.value,
        source_code: _data.source_code,
        contract_name: _data.contract_name,
        constructor_args: _data.constructor_args,
        license_type: _data.license_type?.value ?? defaultLicenseType,
      };
    }

    case "vyper-multi-part": {
      const _data = data as any;

      const body = new FormData();
       body.set("compiler_version", _data.compiler_version.value);
      _data.evm_version && body.set("evm_version", _data.evm_version.value);
      body.set("license_type", _data.license_type?.value ?? defaultLicenseType);
      addFilesToFormData(body, files, "files");
      addFilesToFormData(body, files, "interfaces");

      return body;
    }

    case "vyper-standard-input": {
      const _data = data as any;

      const body = new FormData();
      body.set("compiler_version", _data.compiler_version.value);
      body.set("license_type", _data.license_type?.value ?? defaultLicenseType);
      addFilesToFormData(body, files, "files");

      return body;
    }

    default: {
      return {};
    }
  }
}

export const errorFocus = (flag?: any) => {
  setTimeout(() => {
    const elem = document.getElementsByClassName("bs-error");
    if (elem && elem[0]) {
      if (flag === "top") elem[0].scrollIntoView({ behavior: "smooth" });
      else
        elem[0].scrollIntoView({
          behavior: "smooth",
          block: "nearest",
          inline: "nearest",
        });
    }
  }, 200);
};

export const hexToDecimal = (hexCode: string) => {
  const decimalNumber = parseInt(hexCode, 16);
  return decimalNumber.toString();
};

export const hexToAddress = (hex: string) => {
  const shortenHex = hex.slice(0, 66);
  return shortenHex.slice(0, 2) + shortenHex.slice(26);
};

export const hexToBytes = (hex: string) => {
  const bytes = [];
  const startIndex = hex.startsWith("0x") ? 2 : 0;
  for (let c = startIndex; c < hex.length; c += 2) {
    bytes.push(parseInt(hex.substring(c, c + 2), 16));
  }
  return bytes;
};

export const hexToUtf8 = (hex: string) => {
  const utf8decoder = new TextDecoder();
  const bytes = new Uint8Array(hexToBytes(hex));

  return utf8decoder.decode(bytes);
};

export const onImgError = (e: any) => {
  e.target.src = ImgError;
};

export const onNFTError = (e: any) => {
  e.target.src = NFTPlaceHolder;
};

export const random = (min = 0, max = 100) =>
  Math.floor(Math.random() * (max - min + 1) + min);
export const formatDecimalWithSubscript = (numberStr: any) => {
  return numberStr // Need to remove this line after enabling this function
  // Unicode subscript digits mapping
  const subscriptMap = {
    '0': '₀',
    '1': '₁',
    '2': '₂',
    '3': '₃',
    '4': '₄',
    '5': '₅',
    '6': '₆',
    '7': '₇',
    '8': '₈',
    '9': '₉'
  };

  // Function to convert a number to subscript
  function toSubscript(num : any) {
    // @ts-ignore
    return num.toString().split('').map((digit: string) => subscriptMap[digit]).join('');
  }

  // Split the input string into integer and decimal parts
  let [integerPart, decimalPart] = numberStr.split('.');

  // If there is no decimal part, return the number as is
  if (!decimalPart) {
    return numberStr;
  }

  // Replace sequences of zeros with "0" followed by subscript indicating the count of zeros
  let formattedDecimal = decimalPart.replace(/(0+)/g, (match: any) => `0${toSubscript(match.length)}`);

  // Concatenate the integer part with the formatted decimal part
  return `${integerPart}.${formattedDecimal}`;
}